import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, DataTable, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock } from '../../../components';
import { StaticImage } from 'gatsby-plugin-image';
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import rccSafetyTable from "../../../assets/REN-safety-small-V2.svg"
import { jobCodes } from '../../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-426: Selected Safety Data and Adverse Reactions`,
    keywords: `keynote-426, adverse reactions`,
    description: `Learn about immune-mediated adverse reactions, their management, and safety data from the KEYNOTE-426 clinical trial for KEYTRUDA® (pembrolizumab).`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/advanced-renal-cell-carcinoma/","@type":"ApprovedIndication","name":"KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA, in combination with axitinib, is indicated for the first-line treatment of patients with advanced renal cell carcinoma (RCC)."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/advanced-renal-cell-carcinoma/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

// image alts - so they do not repeat in mobile & desktop
const rccSafetyAlt = "Adverse Reactions Occurring in ≥20% of Patients Receiving KEYTRUDA® (pembrolizumab) With Axitinib in KEYNOTE-426"

//from /src/util/data/indications.js
const indicationId = 8;
const jobCode = jobCodes[33].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Additional Safety Data",
            items: [
                { text: 'Adjuvant RCC', url: '/safety/adverse-reactions/adjuvant-renal-cell-carcinoma' },
                ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Incidence of Selected Immune-Mediated Adverse Reactions' }, 
    { label: 'Management of Selected Immune-Mediated Adverse Reactions' }, 
    { label: 'Safety Data from KEYNOTE&#8288;-&#8288;426' }
]

const rccSafetyFootnotes = [
    {
        label: "c.",
        text: "Graded per NCI CTCAE v4.03."
    },
    {
        label: "d.",
        text: "Includes diarrhea, colitis, enterocolitis, gastroenteritis, enteritis, enterocolitis hemorrhagic."
    },
    {
        label: "e.",
        text: "Includes hypertension, blood pressure increased, hypertensive crisis, labile hypertension."
    },
    {
        label: "f.",
        text: "Includes ALT increased, AST increased, autoimmune hepatitis, blood bilirubin increased, drug-induced liver injury, hepatic enzyme increased, hepatic function abnormal, hepatitis, hepatitis fulminant, hepatocellular injury, hepatotoxicity, hyperbilirubinemia, immune-mediated hepatitis, liver function test increased, liver injury, transaminases increased."
    },
    {
        label: "g.",
        text: "Includes rash, butterfly rash, dermatitis, dermatitis acneform, dermatitis atopic, dermatitis bullous, dermatitis contact, exfoliative rash, genital rash, rash erythematous, rash generalized, rash macular, rash maculopapular, rash papular, rash pruritic, seborrheic dermatitis, skin discoloration, skin exfoliation, perineal rash."
    }
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        
                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                        <GlobalSafetyData />

                        {/* RCC Specific Safety Data */}
                        <PageSection title={anchorLinkModelData[2].label} >
                            <DataTable
                                title='Adverse Reactions Occurring in ≥20% of Patients Receiving KEYTRUDA With Axitinib'
                                mobileImage={() => <img src={rccSafetyTable} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={rccSafetyAlt}/>}
                                desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/REN-safety-large-V2.png" alt={rccSafetyAlt}/>}
                                additionalInformation={[
                                    {
                                        title: 'Additional Information',
                                        text: `
                                        <p>In KEYNOTE&#8288;-&#8288;426, when KEYTRUDA was administered in combination with axitinib, fatal adverse reactions occurred in 3.3% of 429 patients. Serious adverse reactions occurred in 40% of patients, the most frequent (≥1%) were hepatotoxicity (7%), diarrhea (4.2%), acute kidney injury (2.3%), dehydration (1%), and pneumonitis (1%).</p>
                                        <p>Permanent discontinuation due to an adverse reaction among patients receiving KEYTRUDA, axitinib, or both: KEYTRUDA or axitinib (31%), KEYTRUDA only (13%), axitinib only (13%), and both KEYTRUDA and axitinib (8%).</p>`
                                    },
                                ]} 
                                footnotes={rccSafetyFootnotes} 
                            />
                        </PageSection>
                    </TemplateColumn>
                    {/* End Main Column */}

                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
